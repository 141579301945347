import React from "react"
import Layout from "../../layout"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import Video from "../../components/Video/index"
import Introduction from "../../components/Introduction"
import Carousel from "../../components/Carousel"
import CarouselMobile from "../../components/Carousel/mobile"
import VideoDetail from "../../components/VideoDetail"
import FeatureLine from "../../components/FeatureLine"
// import SpecsTable from "../../components/SpecsTable"
import FormContact from "../../components/FormContact"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import FeatureSliderMobile from "../../components/FeatureSliderMobile"
import FeatureLineMobile from "../../components/FeatureLine/mobile"
import VideoDetailMobile from "../../components/VideoDetail/mobile"
import Configurador from "../../components/Configurador/"

// ASSETS
import MicroramVideo from "../../assets/videos/Microram/microram.mp4"
import MicroramVideoPreview from "../../assets/videos/Microram/microram_preview.mp4"
import MicroramDetailVideo from "../../assets/videos/Microram/microram_2.mp4"
import MicroramDetailVideo2 from "../../assets/videos/Microram/microram_3.mp4"
import MicroramDetailVideoMobile from "../../assets/videos/Microram/mobile/microram-video-detail-1.mp4"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'


const IndexPage = ({ location }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language
  var config = true
  if(activeLanguage === 'br' || activeLanguage === 'mx' ){
    config = false
  }
  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO
        pathname={location.pathname}
        title={t('meta', { returnObjects: true })['title']}
        keywords={t('meta', { returnObjects: true })['keywords']}
        description={t('meta', { returnObjects: true })['description']}
        img={t('meta', { returnObjects: true })['image']}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
        domain={location?.hostname}
      />
      <Video
        className="full-width full-height scrollable"
        srcp={MicroramVideo}
        src={MicroramVideoPreview}
        poster="https://ramalhos.com/video_posters/Microram/microram.jpg"
        width="100%"
        id="video-microram"
        imgMobile={t('video', { returnObjects: true })['bgMobile']}
        fullString={t('videoHome', { returnObjects: true })['info']}
        toClick="#introduction"
        translation={t('video', { returnObjects: true })}
      />

      <Introduction
        className="scrollable"
        id="introduction"
        translation={t('introduction', { returnObjects: true })}
        lang={activeLanguage}
        link={"../../config-microram"}
        configurador
      />

      {/* IF MOBILE */}
      {breakpoints.tl ? (
        <CarouselMobile
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-microram-mobile"
            carouselContent={t('carousel', { returnObjects: true })}
        />
      ) : (
        //IF NOT
        <Carousel
          showArrows={false}
          showIndicators={true}
          autoPlay={false}
          transitionTime={0}
          id="carousel-microram"
          carouselContent={t('carousel', { returnObjects: true })}
        />
      )}

      {/* --- */}

      {/* DESKTOP */}

      {!breakpoints.tl && (
        <FeatureLine
          id="first-line"
          _title={t('feature', { returnObjects: true })['title1']}
          text={t('feature', { returnObjects: true })['text1']}
          image={t('feature', { returnObjects: true })['image1_1']}
          midBrightness
        />
      )}

      {!breakpoints.tl && (
        <VideoDetail
          src={ MicroramDetailVideo}
          poster="https://ramalhos.com/video_posters/Microram/microram_2.jpg"
          id="video-detail-microram"
          _title={t('video', { returnObjects: true })['title1']}
          text1={t('video', { returnObjects: true })['text1_1']}
          text2={t('video', { returnObjects: true })['text1_2']}
        />
      )}

      {!breakpoints.tl && (
        <FeatureLine
          id="microram-featured"
          _title={t('feature', { returnObjects: true })['title2']}
          image={t('feature', { returnObjects: true })['image2_1']}
          positionCenter
        />
      )}

      {!breakpoints.tl && (
        <VideoDetail
          src={MicroramDetailVideo2}
          poster="https://ramalhos.com/video_posters/Microram/microram_3.jpg"
          id="video-detail-microram2"
          _title={t('video', { returnObjects: true })['title2']}
          positionCenter
        />
      )}

      {/* --- */}

      {/* MOBILE  */}

      {breakpoints.tl && (
        <FeatureSliderMobile
          id="first-line-mobile"
          _title={t('feature', { returnObjects: true })['title1']}
          text={t('feature', { returnObjects: true })['text1']}
          image1={t('feature', { returnObjects: true })['image1_1M']}
          image2={t('feature', { returnObjects: true })['image1_2M']}
        />
      )}

      {breakpoints.tl && (
        <FeatureLineMobile
          id="second-line-mobile"
          _title={t('video', { returnObjects: true })['title1']}
          image={t('feature', { returnObjects: true })['image2_1M']}
          positionCenter
        />
      )}

      {breakpoints.tl && (
        <VideoDetailMobile
          src={MicroramDetailVideoMobile}
          poster="https://ramalhos.com/video_posters/Microram/mobile/microram-video-detail-1.jpg"
          id="video-detail-microram-mobile"
          _title={t('video', { returnObjects: true })['title2']}
          smallHeight
        />
      )}

      {/* --- */}
      
      {(config) && <Configurador id="microram-initial" content={t("configurador", { returnObjects: true })}/>}

      {/* <SpecsTable
        id="specs-table"
        specsTable={t('specs', { returnObjects: true })['content']}
        specsLength={t('specs', { returnObjects: true })['content'].length}
        location={location}
        btnString="Contacte-nos"
        svgWidth="130px"
      /> */}

      {!(activeLanguage === 'br') &&
            <FormContact 
                id="form-contact" 
                info={t('form', { returnObjects: true })}
                lang={activeLanguage} 
                location={location}
            />
        }
    </Layout>
  )
}

export default IndexPage

export const QueryMicroramPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "microram", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
